import { Alert, Box, Button, Snackbar } from '@mui/material';
import React from 'react';
import { useServiceWorker } from '../../hooks/useServiceWorker';

const UpdateNotification = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const handleUpdateNotificationOpen = () => {
    if (showReload && waitingWorker) {
      return true;
    } else return false;
  };

  const handleUpdateNotificationClose = () => {
    reloadPage();
  };

  return (
    <Snackbar
      open={handleUpdateNotificationOpen()}
      // autoHideDuration={6000}
      onClose={handleUpdateNotificationClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box>
        <Alert
          onClose={handleUpdateNotificationClose}
          severity="info"
          sx={{ width: '100%' }}
        >
          <>
            A new version of this page is available
            <Button
              variant="outlined"
              sx={{ marginTop: '8px' }}
              onClick={() => reloadPage()}
            >
              REFRESH
            </Button>
          </>
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default UpdateNotification;
