import gql from 'graphql-tag';

export const GET_CURRENT_CUSTOMER = gql`
  query GetCustomerProfile($token: String!) {
    getCustomerProfile(token: $token) {
      email
      firstName
      lastName
      vid
      role {
        name
      }
      profilePicture {
        url
        approved
        timeOfApproval
      }
      token
      elbCards {
        id
        status
        cardNumber
        onlineCardNumber
        expirationDate
      }
      hasSubmittedHubspotForm
    }
  }
`;
