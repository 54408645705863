import { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  MobileStepper,
  Paper,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Logo, PinForm, EmailForm } from '../components';
import useCurrentUser from '../hooks/useCurrentUser';
import useStepLogic from '../hooks/useStepLogic';

const LoginPage = () => {
  const { currentUser: customerProfile, loading } = useCurrentUser();
  const { activeStep, handleBack, handleNext, maxSteps, getStepName } =
    useStepLogic();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (!loading && customerProfile) {
      navigate('profile');
    }
  }, [customerProfile, loading, navigate]);

  const loginToRestrictedPage = () => {
    navigate('profile');
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return <EmailForm goToNextStep={handleNext} setEmail={setEmail} />;
      case 1:
        return (
          <PinForm
            loginToRestrictedPage={loginToRestrictedPage}
            email={email}
          />
        );
      default:
        return <EmailForm goToNextStep={handleNext} setEmail={setEmail} />;
    }
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        padding: 0,
        margin: 0,
      }}
      disableGutters
    >
      <CssBaseline />
      <Logo />
      <Paper
        square
        elevation={0}
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pt: 4,
          mt: '8px',
          bgcolor: 'background.default',
          textAlign: 'center',
        }}
      >
        <Typography>{getStepName()}</Typography>
        <Box sx={{ maxWidth: 400, width: '100%', p: 2 }}>{getStep()}</Box>
      </Paper>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{ top: 'auto', bottom: 0, left: 0, maxWidth: 'sm', zIndex: 999 }}
      >
        <MobileStepper
          variant="dots"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <Button disabled={activeStep === maxSteps - 1} />
            ) : (
              <Button
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            )
          }
          backButton={
            <Button onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
          sx={{
            color: 'black',
            bgcolor: 'background.default',
          }}
        />
      </AppBar>
    </Container>
  );
};

export default LoginPage;
