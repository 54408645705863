import { Alert, Box, Snackbar } from '@mui/material';

interface IProps {
  isError: boolean;
  setIsError: (value: any) => void;
}
const ErrorNotification = ({ isError, setIsError }: IProps) => {
  return (
    <Snackbar
      open={isError}
      autoHideDuration={6000}
      onClose={() => setIsError(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Box>
        <Alert
          onClose={() => setIsError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          Error! {JSON.stringify(isError)}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default ErrorNotification;
