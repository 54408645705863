import { Alert, Box, Stack } from '@mui/material';
import TabPanel from '../TabPanel';
import packageJson from '../../../package.json';
import useNetwork from '../../hooks/useNetwork';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const QRCode = require('qrcode.react');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Barcode = require('react-barcode');

interface IPops {
  tab: number;
  onlineCardNumber: string | undefined;
  elbCardNumber: string | undefined;
}

const QrCodeTab = ({ tab, onlineCardNumber, elbCardNumber }: IPops) => {
  const isOnline = useNetwork();
  return (
    <TabPanel value={tab} index={1}>
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        marginTop="8px"
      >
        <Box width="100%" display="flex" justifyContent="center">
          {(onlineCardNumber || elbCardNumber) && (
            <Stack
              spacing={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {!isOnline && (
                <Box>
                  <Alert severity={'error'} sx={{ width: '100%' }}>
                    You are offline
                  </Alert>
                </Box>
              )}
              <QRCode
                value={onlineCardNumber ?? elbCardNumber}
                size={250}
                bgColor={'#ffffff'}
                fgColor={'#000'}
              />
              <Box display="flex" justifyContent="center" width="90%">
                <Barcode
                  width={2}
                  displayValue={false}
                  lineColor="#000"
                  value={onlineCardNumber ?? elbCardNumber}
                />
              </Box>
            </Stack>
          )}
        </Box>
        {packageJson?.version && <p>App version: {packageJson.version}</p>}
      </Stack>
    </TabPanel>
  );
};

export default QrCodeTab;
