import { ChangeEvent, useEffect, useRef, useState } from 'react';
import resizeFile from '../utils/resizeFile';
import { useMutation } from '@apollo/client';
import { UPLOAD_PROFILE_PICTURE_MUTATION } from '../graphql/uploadProfilePicture';
import { CurrentUser } from '../contexts/CurrentUserContext';

const IMAGE_QUALITY = 0.2;

function useProfilePictureManagement(customerProfile: CurrentUser | undefined) {
  // Profile picture handling
  const [profilePicture, setProfilePicture] = useState<string>(
    customerProfile?.profilePicture?.url || '',
  );
  const [isFileSelectorOpen, setIsFileSelectorOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  // Cropping
  const [upImg, setUpImg] = useState<string | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [isError, setIsError] = useState<any>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [missingUploadBlob, setMissingUploadBlob] = useState(false);
  const [uploadModalTab, setUploadModalTab] = useState(0);

  const handleMutationStateChange = () => {
    // Redirect user to first tab after successful image upload
    // This is important for the second (and after) opening the upload modal
    setUploadModalTab(0);
    setIsUploading(false);
  };

  const [
    uploadProfilePicture,
    { data: uploadedProfilePictureResponse, loading: isUploadMutationLoading },
  ] = useMutation(UPLOAD_PROFILE_PICTURE_MUTATION, {
    onCompleted: (data) => {
      console.log('Image upload mutation completed!', data);
      setIsError(null);
      handleMutationStateChange();
    },
    onError: (error) => {
      console.log('Error during Image upload mutation:', error);
      setIsError(error);
      handleMutationStateChange();
    },
  });

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(
        'onFileSelect event.target.files[0]: ',
        event.target.files[0],
      );
      setSelectedFile(event.target.files[0]);
      console.log('onFileSelect selectedFile: ', selectedFile);
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result as string));
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const uploadFileToProfilePicture = async () => {
    setIsUploading(true);
    if (previewCanvasRef?.current) {
      console.log(
        'uploadFileToProfilePicture previewCanvasRef?.current: ',
        previewCanvasRef?.current,
      );
      previewCanvasRef.current.toBlob(
        async (blob) => {
          console.log('ProfilePage: start resizing...');
          setIsResizing(true);
          console.log('uploadFileToProfilePicture blob: ', blob);
          if (blob) {
            console.log('ProfilePage: we have blob');
            setMissingUploadBlob(false);
            try {
              console.log('ProfilePage: send blob to resizer...');
              const resizedImageBlob = (await resizeFile(blob)) as Blob;
              console.log('ProfilePage: resizing finished!');
              setIsResizing(false);
              console.log('ProfilePage: start uploading...');
              uploadProfilePicture({ variables: { data: resizedImageBlob } });
            } catch (error) {
              if (error instanceof Error) {
                setIsError(error?.message);
              } else {
                setIsError(error);
              }
              console.log(error);
              setIsResizing(false);
            } finally {
              console.log('uploadFileToProfilePicture finally');
              setIsResizing(false);
            }
          } else {
            console.log('uploadFileToProfilePicture missing blob!');
            setMissingUploadBlob(true);
            setIsUploading(false);
            setIsResizing(false);
          }
        },
        'image/webp',
        IMAGE_QUALITY,
      );
    }
  };

  useEffect(() => {
    if (customerProfile?.profilePicture?.url) {
      setProfilePicture(customerProfile?.profilePicture?.url);
    } else {
      setProfilePicture('');
    }
  }, [customerProfile]);

  useEffect(() => {
    const tempUser = JSON.parse(String(localStorage.getItem('user')));
    if (uploadedProfilePictureResponse) {
      setProfilePicture(
        uploadedProfilePictureResponse?.uploadProfilePicture?.url,
      );
      setIsUploading(false);
      setIsFileSelectorOpen(false);
      setSelectedFile(null);
      if (tempUser != null) {
        tempUser.profilePicture.url =
          uploadedProfilePictureResponse?.uploadProfilePicture?.url;
        if (tempUser.profilePicture.timeOfApproval !== null) {
          tempUser.profilePicture.timeOfApproval = null;
        }
        localStorage.setItem('user', JSON.stringify(tempUser));
      }
    }
  }, [uploadedProfilePictureResponse]);
  return {
    profilePicture,
    selectedFile,
    upImg,
    previewCanvasRef,
    onFileSelect,
    uploadFileToProfilePicture,
    isUploadMutationLoading,
    isFileSelectorOpen,
    isUploading,
    isError,
    isResizing,
    missingUploadBlob,
    uploadModalTab,
    setIsError,
    setMissingUploadBlob,
    setSelectedFile,
    setIsFileSelectorOpen,
    setUploadModalTab,
  };
}
export default useProfilePictureManagement;
