/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from '@mui/material';
import { useEffect } from 'react';

const HubspotForm = () => {
  const HUBSPOT_FORM = process.env
    .REACT_APP_HUBSPOT_ELB_CARD_FORM_URL as string;
  console.log(HUBSPOT_FORM);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.addEventListener('error', (scriptLoadingError) => {
      console.log('scriptLoadingError', scriptLoadingError);
      if ((window as any).hbspt) {
        //@ts-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20225804',
          formId: '0709446b-6f9e-4e82-b09c-7c7a977fe6db',
          target: '#hubspotForm',
        });

        const form = document.getElementsByClassName('hpspt-form');
        console.log('scriptLoadingError', { form });
      }
    });
    script.addEventListener('load', () => {
      //@ts-ignore
      if (window.hbspt) {
        //@ts-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20225804',
          formId: '0709446b-6f9e-4e82-b09c-7c7a977fe6db',
          target: '#hubspotForm',
        });

        const form = document.getElementsByClassName('hpspt-form');
        console.log({ form });
      }
    });
    script.src = HUBSPOT_FORM;
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div
        id="hubspotForm"
        style={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          target="_blank"
          href={HUBSPOT_FORM}
          variant="contained"
          color="primary"
        >
          Open HubSpot form on new page
        </Button>
      </div>
    </div>
  );
};

export default HubspotForm;
