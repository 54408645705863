import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material';
import React from 'react';
import ProfilePicture from './ProfilePicture';
import CardCodes from './CardCodes';
import CardDetails from './CardDetails';
import TabPanel from '../TabPanel';

interface IProps {
  tab: number;
  loading: boolean;
  setIsFileSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  profilePicture: string;
  elbCardNumber: string | undefined;
  onlineCardNumber: string | undefined;
  dateValidUntil: Date | null;
  handleOpen: () => void;
  logoutModal: boolean;
  handleClose: () => void;
  logout: () => void;
}

const HOW_TO_USE_GUIDE_URL = process.env
  .REACT_APP_HOW_TO_USE_GUIDE_URL as string;

const ProfilePictureTab = ({
  tab,
  loading,
  setIsFileSelectorOpen,
  profilePicture,
  elbCardNumber,
  onlineCardNumber,
  dateValidUntil,
  handleOpen,
  logoutModal,
  handleClose,
  logout,
}: IProps) => {
  return (
    <TabPanel value={tab} index={0}>
      {loading ? (
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          marginTop="100px"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Grid container>
            <Grid item xs={12}>
              <ProfilePicture
                setIsFileSelectorOpen={setIsFileSelectorOpen}
                profilePicture={profilePicture}
              />
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '16px' }}>
              <CardCodes
                elbCardNumber={elbCardNumber}
                onlineCardNumber={onlineCardNumber}
              />
              <Grid item xs={12}>
                <CardDetails dateValidUntil={dateValidUntil} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: '16px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>
                <Box
                  sx={{
                    paddingBottom: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button href={HOW_TO_USE_GUIDE_URL} variant="contained">
                    How To Use Guide
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button variant="contained" onClick={handleOpen}>
                    Logout
                  </Button>
                </Box>
                <Dialog
                  open={logoutModal}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Are you sure you want to log out?'}
                  </DialogTitle>
                  <DialogActions>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button variant="contained" onClick={logout}>
                        Logout
                      </Button>
                    </Stack>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
          </Grid>
        </Stack>
      )}
    </TabPanel>
  );
};

export default ProfilePictureTab;
