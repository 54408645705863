import { Tabs, Tab } from '@mui/material';
import { QrCode2, AccountCircle } from '@mui/icons-material';

interface IProps {
  loading: boolean;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ProfileHeader({ loading, onChange, value }: IProps) {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      aria-label="basic tabs example"
      variant="fullWidth"
      centered
    >
      <Tab
        disabled={loading}
        icon={<AccountCircle />}
        label="Profile picture"
        {...a11yProps(0)}
      />
      <Tab
        disabled={loading}
        icon={<QrCode2 />}
        label="QR code"
        {...a11yProps(1)}
      />
    </Tabs>
  );
}

export default ProfileHeader;
