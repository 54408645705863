import React, { useState, useEffect, useMemo, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Alert,
  AlertColor,
  Button,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { GENERATE_OTP_MUTATION } from '../graphql/generateOTP';
import { useMutation } from '@apollo/react-hooks';
import useNetwork from '../hooks/useNetwork';
import GlobalLogOutContext from '../contexts/GlobalLogOutContext';
import { useServiceWorker } from '../hooks/useServiceWorker';
import packageJson from '../../package.json';

interface EmailFormProps {
  goToNextStep: () => void;
  setEmail(email: string): void;
}

const EmailForm: React.FC<EmailFormProps> = ({ goToNextStep, setEmail }) => {
  const { globalLogOut, setGlobalLogOut } = useContext(GlobalLogOutContext);
  const [openApiResponseSnackbar, setOpenApiResponseSnackbar] = useState(false);
  const [isForcedLogoutMessage, setIsForcedLogoutMessage] = useState(false);
  const [snackVariant, setSnackVariant] = useState<AlertColor>('success');
  const [snackText, setSnackText] = useState(
    'Open your email inbox and get your PIN code!',
  );
  const isOnline = useNetwork();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenApiResponseSnackbar(false);
  };

  const validationSchema = yup.object({
    emailAddress: yup
      .string()
      .email()
      .required('You must add your email address!'),
  });

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
    },
    validationSchema,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const [login, { loading, error, data }] = useMutation(GENERATE_OTP_MUTATION);

  const isSuccess = useMemo(() => {
    return data?.generateOTP === formik.values.emailAddress || false;
  }, [data, formik.values.emailAddress]);

  useEffect(() => {
    if (isSuccess) {
      goToNextStep();
      setEmail(formik.values.emailAddress);
    }
  }, [isSuccess, goToNextStep, formik.values.emailAddress, setEmail]);

  useEffect(() => {
    if (error) {
      setSnackVariant('error');
      //console.log('error', Object.entries(error));
      //console.log('error', error.graphQLErrors);
      let noCustomerWithSuchEmail = false;
      let invalidEmail = false;
      if (error?.graphQLErrors) {
        for (const graphQLError of error.graphQLErrors) {
          const graphQLErrorMessage: any = graphQLError?.message;
          //console.log('graphQLErrorMessage: ', graphQLErrorMessage);
          if (
            graphQLErrorMessage?.globals?.some(
              (global: string) =>
                global ===
                'There is no customer with the provided e-mail address.',
            )
          ) {
            noCustomerWithSuchEmail = true;
          }
          if (
            graphQLErrorMessage?.fields?.some(
              (global: any) => global.message === 'email must be a valid email',
            )
          ) {
            invalidEmail = true;
          }
        }
      }

      if (noCustomerWithSuchEmail || invalidEmail) {
        setSnackText(
          'Invalid email address format or we cannot find an ELB Card subscription with this email address. ',
        );
      } else {
        setSnackText('Something went wrong. Please try again later!');
      }
      setOpenApiResponseSnackbar(true);
    }
  }, [error]);

  const handleRequestPinButtonClick = () => {
    login({ variables: { email: formik.values.emailAddress } });
  };

  const handleForcedLogoutSnackbarClose = () => {
    setIsForcedLogoutMessage(false);
    setGlobalLogOut?.(undefined);
  };

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  const handleUpdateNotificationOpen = () => {
    if (showReload && waitingWorker) {
      return true;
    } else return false;
  };

  const handleUpdateNotificationClose = () => {
    reloadPage();
  };

  useEffect(() => {
    if (globalLogOut) {
      setIsForcedLogoutMessage(true);
    }
  }, [globalLogOut]);

  const HOW_TO_USE_GUIDE_URL = process.env
    .REACT_APP_HOW_TO_USE_GUIDE_URL as string;

  const BUY_ELB_CARD_URL = process.env.REACT_APP_BUY_ELB_CARD_URL as string;

  return (
    <Box>
      <Snackbar
        open={openApiResponseSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleClose}
            severity={snackVariant}
            sx={{ width: '100%' }}
          >
            {snackText}
            {snackVariant === 'success' && (
              <LinearProgress color="success" sx={{ marginTop: '8px' }} />
            )}
          </Alert>
        </Box>
      </Snackbar>
      <Snackbar
        open={isForcedLogoutMessage}
        autoHideDuration={10000}
        onClose={handleForcedLogoutSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleForcedLogoutSnackbarClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            You were force logged out! Because you logged in with this account
            on another device. You can only use the app actively on one device
            at a time.
          </Alert>
        </Box>
      </Snackbar>
      <Snackbar
        open={handleUpdateNotificationOpen()}
        // autoHideDuration={6000}
        onClose={handleUpdateNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box>
          <Alert
            onClose={handleUpdateNotificationClose}
            severity="info"
            sx={{ width: '100%' }}
          >
            <>
              A new version of this page is available
              <Button
                variant="outlined"
                sx={{ marginTop: '8px' }}
                onClick={() => reloadPage()}
              >
                REFRESH
              </Button>
            </>
          </Alert>
        </Box>
      </Snackbar>
      <TextField
        variant="outlined"
        fullWidth
        id="emailAddress"
        name="emailAddress"
        label="Email address"
        autoComplete="emailAddress"
        value={formik.values.emailAddress}
        onChange={formik.handleChange}
        error={
          formik.touched.emailAddress && Boolean(formik.errors.emailAddress)
        }
        helperText={formik.touched.emailAddress && formik.errors.emailAddress}
        inputProps={{
          autoCapitalize: 'none',
        }}
        type="email"
      />
      <Typography variant="body1" sx={{ marginTop: '16px' }}>
        Type your email address, touch the <b>Get PIN code</b> button, then go
        to the next step!
      </Typography>
      <Stack spacing={2}>
        <Button
          variant="contained"
          disabled={
            !(formik.isValid && formik.dirty) ||
            loading ||
            isSuccess ||
            !isOnline
          }
          onClick={() => handleRequestPinButtonClick()}
          sx={{ marginTop: '16px' }}
        >
          Get PIN code
        </Button>

        <Box>
          <Button href={BUY_ELB_CARD_URL} variant="contained">
            Buy ELB card
          </Button>
        </Box>

        <Box>
          <Button href={HOW_TO_USE_GUIDE_URL} variant="contained">
            How To Use Guide
          </Button>
        </Box>

        <Box>
          {packageJson?.version && <p>App version {packageJson.version}</p>}
        </Box>

        <br />
        {!isOnline && (
          <Box>
            <Alert severity={'error'} sx={{ width: '100%' }}>
              You are offline
            </Alert>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default EmailForm;
