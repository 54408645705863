import React from 'react';

export interface ELBCard {
  id: number;
  status: string;
  cardNumber: string;
  onlineCardNumber: string;
  expirationDate: Date;
}

export interface CurrentUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  vid: number;
  profilePicture: {
    url: string;
    approved: boolean;
    timeOfApproval?: Date | null;
  };
  token: string;
  elbCards: ELBCard[];
  hasSubmittedHubspotForm: boolean;
}

const CurrentUserContext = React.createContext<{
  currentUser?: CurrentUser;
  setCurrentUser?: React.Dispatch<CurrentUser | undefined>;
}>({});

export const CurrentUserProvider = CurrentUserContext.Provider;
export const CurrentUserConsumer = CurrentUserContext.Consumer;

export default CurrentUserContext;
