import { useCallback, useState } from 'react';

const initialStep = 0;
const maxSteps = 2;

function useStepLogic() {
  const [activeStep, setActiveStep] = useState(initialStep);

  const handleNext = useCallback(() => {
    if (activeStep < maxSteps - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [activeStep, maxSteps]);

  const handleBack = useCallback(() => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }, [activeStep]);

  const getStepName = () => {
    switch (activeStep) {
      case 0:
        return 'Type your email address';
      case 1:
        return 'Validate your PIN code';
      default:
        return 'Type your email address';
    }
  };

  return {
    activeStep,
    handleNext,
    handleBack,
    maxSteps,
    getStepName,
  };
}
export default useStepLogic;
