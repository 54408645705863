import { useEffect, useState } from 'react';
import {
  CssBaseline,
  Container,
  Typography,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import { useNavigate } from 'react-router-dom';
import { Logo, UploadPictureDialog } from '../components';
import {
  ProfileHeader,
  ProfilePictureTab,
  QrCodeTab,
} from '../components/profile-page';
import useCurrentUser from '../hooks/useCurrentUser';
import useProfilePictureManagement from '../hooks/useProfilePictureManagement';
import UpdateNotification from '../components/profile-page/UpdateNotification';
import ErrorNotification from '../components/profile-page/ErrorNotification';
import HubspotForm from '../components/profile-page/HubspotForm';

export default function ProfilePage() {
  const {
    currentUser: customerProfile,
    error,
    loading,
    shouldRedirect,
    logout,
    dateValidUntil,
    elbCardNumber,
    onlineCardNumber,
    hasSubmittedHubspotForm,
    getCustomerProfile,
  } = useCurrentUser();

  const {
    profilePicture,
    selectedFile,
    upImg,
    previewCanvasRef,
    onFileSelect,
    uploadFileToProfilePicture,
    isUploadMutationLoading,
    isFileSelectorOpen,
    isUploading,
    isError,
    isResizing,
    missingUploadBlob,
    uploadModalTab,
    setMissingUploadBlob,
    setSelectedFile,
    setIsFileSelectorOpen,
    setUploadModalTab,
    setIsError,
  } = useProfilePictureManagement(customerProfile);

  const navigate = useNavigate();
  useEffect(() => {
    if (error || shouldRedirect) {
      navigate('/');
      //console.log('error', error);
    }
  }, [error, navigate, shouldRedirect]);

  const [tab, setTab] = useState(0);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleOpen = () => {
    setLogoutModal(true);
  };
  const handleClose = () => {
    setLogoutModal(false);
  };

  return (
    <Container
      component="main"
      maxWidth="sm"
      sx={{
        height: '100vh',
        padding: 0,
        margin: 0,
      }}
      disableGutters
    >
      <CssBaseline />
      <Logo />
      {hasSubmittedHubspotForm ? (
        <>
          <ProfileHeader
            loading={loading}
            value={tab}
            onChange={handleChange}
          />
          <UpdateNotification />
          <ProfilePictureTab
            tab={tab}
            loading={loading}
            setIsFileSelectorOpen={setIsFileSelectorOpen}
            profilePicture={profilePicture}
            elbCardNumber={elbCardNumber}
            onlineCardNumber={onlineCardNumber}
            dateValidUntil={dateValidUntil}
            handleClose={handleClose}
            handleOpen={handleOpen}
            logoutModal={logoutModal}
            logout={logout}
          />
          <QrCodeTab
            tab={tab}
            onlineCardNumber={onlineCardNumber}
            elbCardNumber={elbCardNumber}
          />
          <UploadPictureDialog
            isOpen={isFileSelectorOpen}
            isUploading={isUploading}
            onClose={() => {
              setIsFileSelectorOpen(false);
              // Redirect user to first tab after closing the upload modal
              // This is important for the second (and after) opening the upload modal
              setSelectedFile(null);
              // setTimeout is needed for better UX
              // Without setTimeout the user could see the tab changes during closing the modal
              setTimeout(() => {
                setUploadModalTab(0);
              }, 500);
            }}
            onFileSelect={onFileSelect}
            previewCanvasRef={previewCanvasRef}
            selectedFile={selectedFile}
            upImg={upImg}
            uploadFileToProfilePicture={uploadFileToProfilePicture}
            tab={uploadModalTab}
            setTab={setUploadModalTab}
            isResizing={isResizing}
            isUploadMutationLoading={isUploadMutationLoading}
            missingUploadBlob={missingUploadBlob}
            setMissingUploadBlob={setMissingUploadBlob}
          />
          <ErrorNotification isError={isError} setIsError={setIsError} />
        </>
      ) : (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignContent={'center'}
          flexWrap={'wrap'}
          flexDirection={'column'}
        >
          <Typography
            style={{
              textAlign: 'center',
              padding: '8px',
            }}
            variant="h6"
          >
            To be able to use the application you need to submit the form below:
          </Typography>
          <HubspotForm />
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            flexWrap={'wrap'}
            style={{ margin: '8px', paddingTop: '8px' }}
          >
            <Typography
              style={{ textAlign: 'center', marginBottom: '3px' }}
              variant="body1"
            >
              Please only click on the below button <b>AFTER</b> submitting the
              above form.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const token = localStorage.getItem('ELB_ERASMUS_CARD_TOKEN');
                getCustomerProfile({
                  variables: {
                    token: token,
                  },
                });
              }}
            >
              Check Form Submission
            </Button>
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignContent={'center'}
            flexWrap={'wrap'}
            style={{ margin: '8px', paddingTop: '8px' }}
          >
            <Button
              variant="contained"
              onClick={() => handleOpen()}
              style={{ marginTop: '8px' }}
            >
              Logout
            </Button>
            <Dialog
              open={logoutModal}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Are you sure you want to log out?'}
              </DialogTitle>
              <DialogActions>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Button onClick={() => handleClose()}>Cancel</Button>
                  <Button variant="contained" onClick={() => logout()}>
                    Logout
                  </Button>
                </Stack>
              </DialogActions>
            </Dialog>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
